import { Col, Row } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import IndentedContent from '../helpers/IndentedContent';
import Container from '../Container';
import { StaticImage } from 'gatsby-plugin-image'
import './Footer.scss';

class Header extends React.Component {
  logoStyles = {
    maxWidth: '150px',
    height: 'auto',
    margin: '0 0 1rem 0',
    display: 'block',
  };

  footerStyles = {
    color: '#ffffff',
  };

  linkStyles = {
    color: '#ffffff',
  };

  linkListStyles = {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  };

  metaStyles = {
    backgroundColor: '#000000'
  };

  headlineStyles = {
    color: '#ffffff',
  };

  footerMainContentStyles = {
    padding: '4rem 0',
  };

  render(props) {
    return (
      <Container containerStyles={{ backgroundColor: '#000000', color: '#ffffff', padding: 0 }}>
        <footer style={{ ...this.footerStyles }}>
          <div style={this.footerMainContentStyles}>
            <IndentedContent>
              <Row>
                <Col span={24} md={{ span: 8 }}>
                <StaticImage src="../../images/logo_white_text.png" alt="petoffice Logo" placeholder="tracedSVG" style={this.logoStyles} />
                  
                  Kontakt: info@petoffice.eu
      {/*+49 (0) 631 / 343 597 36*/}
                </Col>
                <Col span={24} md={{ span: 8 }}>
                  <h3 style={this.headlineStyles}>Rechtliches</h3>

                  <ul style={this.linkListStyles}>
                    <li><Link style={this.linkStyles} to="/agb/">AGB</Link></li>
                    <li><Link style={this.linkStyles} to="/datenschutz/">Datenschutzerklärung</Link></li>
                    <li><Link style={this.linkStyles} to="/haftungsausschluss/">Haftungsausschluss</Link></li>
                    <li><Link style={this.linkStyles} to="/impressum/">Impressum</Link></li>
                  </ul>
                </Col>
                <Col span={24} md={{ span: 8 }}>
                  <h3 style={this.headlineStyles}>Support</h3>

                  <ul style={this.linkListStyles}>
                    <li><a style={this.linkStyles} href="https://docs.petoffice.eu/" target="_blank">Handbuch</a></li>
                    {/*<li><a style={this.linkStyles} href="#">Videokurse</a></li>*/}
                    <li><Link style={this.linkStyles} to="/posts/">Blog</Link></li>
                  </ul>

                </Col>
              </Row>
            </IndentedContent>
          </div>

          <div style={this.metaStyles}>
            <IndentedContent>
              <div style={{ textAlign: 'center', padding: '1rem 0' }}>
                Copyright &copy; David Prandzioch<br />
                Alle Rechte vorbehalten
              </div>
            </IndentedContent>
          </div>
        </footer>
      </Container>
    )
  }
}

export default Header;
