import * as React from "react"

const containerStyles = {
  maxWidth: '1400px',
  margin: '0 auto',
  padding: '0 1rem',
};

class IndentedContent extends React.Component {
  render() {
    return (
      <div style={{...containerStyles, ...this.props.styles || {}}}>
        {this.props.children}
      </div>
    );
  }
}

export default IndentedContent;