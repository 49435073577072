import { Col, Row, Menu, Button } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import TenantCreation from '../TenantCreation';
import { MenuOutlined } from '@ant-design/icons';
import { StaticImage } from 'gatsby-plugin-image'

const { SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTenantCreation: false,
      open: false,
    };
  }
  
  logoStyles = {
    maxWidth: '80%',
    height: 'auto',
  };

  menuStyles = {
    borderBottom: 'none',
  };

  headerStyles = {
    padding: '20px 0',
  };

  toggleMenu() {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  render(props) {
    return (
      <header style={{ ...this.headerStyles }}>
        <div className="header">
          <div className="header__burger" onClick={() => this.toggleMenu()}>
            <MenuOutlined />
          </div>

          <div className="header__logo">
            <Link to="/">
              <StaticImage src="../../images/logo.svg" alt="petoffice - Software für den Tierschutz" placeholder="tracedSVG" height={50} />
            </Link>
          </div>

          <ul className={`header__navigation ${this.state.open ? ' header__navigation--open' : ''}`}>
            {/*<li className="header__main-item">
              <Link className="header__main-link header__menu-item" to="">Warum petoffice</Link>
            </li>*/}
            <li className="header__main-item">
              <Link className="header__main-link header__menu-item" to="/roadmap/">Roadmap</Link>
            </li>
{/* 
            <li className="header__main-item">
              <Link className="header__main-link header__menu-item" to="">Produkt</Link>

              <ul className="header__submenu">
                <li className="header__submenu-item">
                  <span className="header__submenu-headline">Pakete</span>
                  
                  <ul className="header__submenu--second">
                    <li className="header__menu-item">petoffice Cloud Premium</li>
                  </ul>
                </li>
                <li className="header__submenu-item">
                  <span className="header__submenu-headline">petoffice Cloud</span>

                  <ul className="header__submenu--second">
                    <li className="header__menu-item">Smartphone App</li>
                    <li className="header__menu-item"><Link to="/roadmap/">Roadmap</Link></li>
                    <li className="header__menu-item"><Link to="/migration/">Migration</Link></li>
                  </ul>
                </li>
              </ul>
            </li>
            */}
            <li className="header__main-item header__main-link header__menu-item">
              <Link to="/preise/">Preise</Link>
            </li>
            <li className="header__main-item">
              <Link className="header__main-link header__menu-item" to="/posts/">Blog</Link>
            </li>
            <li className="header__main-item">
              <a target="_blank" className="header__main-link header__menu-item" href="https://docs.petoffice.eu/">Handbuch</a>
            </li>
            <li className="header__main-item header__menu-try">
              <div className="header__menu-item" style={{ marginRight: '10px' }}>
                <Button type="secondary" onClick={() => { window.open('https://app.petoffice.eu/', '_blank'); }}>Login für Vereine</Button>
              </div>

              <div className="header__menu-item">
                <Button type="primary" onClick={() => this.setState({ showTenantCreation: true })}>Jetzt kostenlos testen</Button>
              </div>
            </li>
          </ul>
        </div>

        <TenantCreation visible={this.state?.showTenantCreation} onCancel={() => this.setState({ showTenantCreation: false })}  onOk={() => this.setState({ showTenantCreation: false })}></TenantCreation>
      </header>
    )
  }
}

export default Header;