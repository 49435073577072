import * as React from "react"
import './Container.scss';

const Container = (props) => {
  const containerStyles = {
    ...props?.containerStyles || {},
  };

  return (
    <div className="container" style={containerStyles}>
      {props.children}
    </div>
  )
}

export default Container;